import React from 'react';
import Layout from '@components/structure/Layout';
import { Helmet } from 'react-helmet';

const Video = () => {
	//var video = Wistia.api('8tu0gsq9ko');
	//console.log('I got a handle to the video!', video);
	return (
		<Layout>
			<Helmet>
				<script
					src='https://fast.wistia.com/embed/medias/7f5c9r1sy2.jsonp'
					async></script>
				<script
					src='https://fast.wistia.com/assets/external/E-v1.js'
					async></script>

				<script>
					var a = 2;
					console.log(a);
				</script>
			</Helmet>

			<section className='container'>
				<h1>Video Sample iFrame</h1>
				<iframe src="//fast.wistia.net/embed/iframe/7f5c9r1sy2" allowtransparency="true" frameborder="0" scrolling="no" class="wistia_embed" name="wistia_embed" allowfullscreen mozallowfullscreen webkitallowfullscreen oallowfullscreen msallowfullscreen width="640" height="360"></iframe>
				
				<h1>Video Sample</h1>
				<div className='wistia_responsive_padding'>
					<div className='wistia_responsive_wrapper'>
						<div className='wistia_embed wistia_async_7f5c9r1sy2 videoFoam=true'>
							<div className='wistia_swatch'>
								<img
									className='wistia_swatch_img'
									src='https://fast.wistia.com/embed/medias/7f5c9r1sy2/swatch'
									alt=''
									aria-hidden='true'
									onload='this.parentNode.style.opacity=1;'
								/>
							</div>
						</div>
					</div>
				</div>
			</section>
		</Layout>
	);
};

export default Video;
